import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';


@Injectable()
export class Authentication implements CanActivate {
    constructor(private router: Router) { }
    canActivate() {
        
        if (window.localStorage.getItem('userLoggedIn') && window.localStorage.getItem('userLoggedIn')!="false") {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}
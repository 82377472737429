import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import icBookmarks from '@iconify/icons-ic/twotone-bookmarks';
import emojioneUS from '@iconify/icons-emojione/flag-for-flag-united-states';
import emojioneDE from '@iconify/icons-emojione/flag-for-flag-germany';
import icMenu from '@iconify/icons-ic/twotone-menu';
import { ConfigService } from '../../services/config.service';
import { map } from 'rxjs/operators';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';
import icBallot from '@iconify/icons-ic/twotone-ballot';
import icDescription from '@iconify/icons-ic/twotone-description';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icDoneAll from '@iconify/icons-ic/twotone-done-all';
import { NavigationService } from '../../services/navigation.service';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import icSearch from '@iconify/icons-ic/twotone-search';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

 
  navigationItems=[];
  isHorizontalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));

  icSearch = icSearch;
  icBookmarks = icBookmarks;
  emojioneUS = emojioneUS;
  emojioneDE = emojioneDE;
  icMenu = icMenu;
  icPersonAdd = icPersonAdd;
  icAssignmentTurnedIn = icAssignmentTurnedIn;
  icBallot = icBallot;
  icDescription = icDescription;
  icAssignment = icAssignment;
  icReceipt = icReceipt;
  icDoneAll = icDoneAll;
  icArrowDropDown = icArrowDropDown;
  name:any;
  permissions:any=[]
  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private navigationService: NavigationService,
              private popoverService: PopoverService) { }

  ngOnInit() {
    if(localStorage.getItem('permissions')) {
      this.permissions =JSON.parse(localStorage.getItem('permissions'));
    }
   
    this.navigationItems = this.navigationService.items;
    let navigationItems = JSON.parse(JSON.stringify(this.navigationService.items));
    const role_id = Number(sessionStorage.getItem('role_id'));
    if(role_id>0) {
      this.navigationItems = this.getChildren(navigationItems);
    }  else {
      this.navigationItems = navigationItems;
    }
  }


  getChildren(data){
     let results:any =[];
    for(let i=0;i<=data.length-1;i++) {
       
       if(data[i].children && data[i].children.length > 0) {
        data[i].children=  data[i].children.filter(data=>{
                const result =this.findScreen(data.screen_id);
                if(!result) {
                  return true;
                } else{
                  return false;
                }
          });
       }
    }
    return data;

  }

  findScreen(screenID) {
   if(screenID ===1 || screenID ===2 || screenID ===3 || screenID ===4 || screenID ===5 || screenID ===6 || screenID ===7) {
    let result = this.permissions?.filter(data=>{
      return (data.screen_id == 1 || data.screen_id == 2 ||data.screen_id == 3 ||data.screen_id == 4 ||data.screen_id == 5 
        ||data.screen_id == 6 ||
        data.screen_id == 7);
    });
    result = result.find(add=>{
      return add.edit ===1;
    })
    //19,20,21,22,23,24,25
    if(!result || result.edit===0) {
      console.log(result);
      return true;
    } else{
      return false;
    }
   }
     
    else if(screenID) {
      let result = this.permissions?.find(data=>{
        return data.screen_id == screenID;
      });
      //19,20,21,22,23,24,25
      if(!result || (result.add ==0 && result.delete ==0 && result.edit ==0 && result.view ==0)) {
        return true;
      } else{
        return false;
      }
    } else {
      return  true;
    }
  }

  openQuickpanel() {
    this.layoutService.openQuickpanel();
  }

  openSidenav() {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement) {
    this.popoverService.open({
      content: MegaMenuComponent,
      origin,
      position: [
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });
  }

  openSearch() {
    this.layoutService.openSearch();
  }
}

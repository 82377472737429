import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/services/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import icLayers from '@iconify/icons-ic/twotone-layers';
import icAssigment from '@iconify/icons-ic/twotone-assignment';
import icContactSupport from '@iconify/icons-ic/twotone-contact-support';
import icDateRange from '@iconify/icons-ic/twotone-date-range';
import icChat from '@iconify/icons-ic/twotone-chat';
import icContacts from '@iconify/icons-ic/twotone-contacts';
import icAssessment from '@iconify/icons-ic/twotone-assessment';
import icLock from '@iconify/icons-ic/twotone-lock';
import icWatchLater from '@iconify/icons-ic/twotone-watch-later';
import icError from '@iconify/icons-ic/twotone-error';
import icAttachMoney from '@iconify/icons-ic/twotone-attach-money';
import icPersonOutline from '@iconify/icons-ic/twotone-person-outline';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icHelp from '@iconify/icons-ic/twotone-help';
import icBook from '@iconify/icons-ic/twotone-book';
import icBubbleChart from '@iconify/icons-ic/twotone-bubble-chart';
import icFormatColorText from '@iconify/icons-ic/twotone-format-color-text';
import icStar from '@iconify/icons-ic/twotone-star';
import icViewCompact from '@iconify/icons-ic/twotone-view-compact';
import icPictureInPicture from '@iconify/icons-ic/twotone-picture-in-picture';
import icSettings from '@iconify/icons-ic/twotone-settings';
import { LayoutService } from '../@vex/services/layout.service';
import icUpdate from '@iconify/icons-ic/twotone-update';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import icChromeReaderMode from '@iconify/icons-ic/twotone-chrome-reader-mode';
import { ConfigName } from '../@vex/interfaces/config-name.model';
import icMail from '@iconify/icons-ic/twotone-mail';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'vex';

  constructor(private configService: ConfigService,
              private styleService: StyleService,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private layoutService: LayoutService,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private splashScreenService: SplashScreenService) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.pipe(
      map(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl'))),
    ).subscribe(isRtl => {
      this.document.body.dir = isRtl ? 'rtl' : 'ltr';
      this.configService.updateConfig({
        rtl: isRtl
      });
    });

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));


    /**
     * Add your own routes here
     */
    this.navigationService.items = [
      
      {
        type: 'subheading',
        label: 'Home',
        children: [
          {
            type: 'link',
            label: 'Dashboard',
            route: '/',
            screen_id:18,
            icon: icAssigment,
            badge: {
              value: '16',
              bgClass: 'bg-cyan',
              textClass: 'text-cyan-contrast',
            },
          },
          {
            type: 'link',
            label: 'Refferal Rebate',
            route: '/apps/online-request',
            screen_id:19,
            icon: icAssigment,
            badge: {
              value: '16',
              bgClass: 'bg-cyan',
              textClass: 'text-cyan-contrast',
            },
          }
         
        ]
      },
         {
        type: 'subheading',
        label: 'Admin',
        children: [
           {
            type: 'link',
            label: 'Manual Purchase Entry',
            route: '/apps/group-org/group-org-list',
            icon: icChat,
            screen_id:11,
            badge: {
              value: '16',
              bgClass: 'bg-cyan',
              textClass: 'text-cyan-contrast',
            },
          },
           {
            type: 'link',
            label: 'Wallet Details',
            route: '/apps/group-org/emp-list',
            icon: icChat,
            screen_id:33,
            badge: {
              value: '16',
              bgClass: 'bg-cyan',
              textClass: 'text-cyan-contrast',
            },
          },
         
         
         
        
        
          {
            type: 'link',
            label: 'Approval List',
            route: '/apps/approval',
            icon: icChat,
            screen_id:1,
            badge: {
              value: '16',
              bgClass: 'bg-cyan',
              textClass: 'text-cyan-contrast',
            },
          },
          {
            type: 'link',
            label: 'Merchant Gift Cards',
            route: '/apps/merchant-cards',
            icon: icChat,
            screen_id:20,
            badge: {
              value: '16',
              bgClass: 'bg-cyan',
              textClass: 'text-cyan-contrast',
            },
          },
          {
            type: 'link',
            label: 'Payment',
            route: '/apps/payment-type',
            icon: icChat,
            screen_id:29,
            badge: {
              value: '16',
              bgClass: 'bg-cyan',
              textClass: 'text-cyan-contrast',
            },
          }
         
        ]
      },
      {
        type: 'subheading',
        label: 'Master',
        children: [
          // {
          //   type: 'link',
          //   label: 'Group Series',
          //   route: '/apps/group-series',
          //   icon: icAssigment,
          //   screen_id:4
          // },
        
          {
            type: 'link',
            label: 'Appointments',
            route: '/apps/tickets/group-ticket-list',
            icon: icDateRange,
            screen_id:41,
            badge: {
              value: '12',
              bgClass: 'bg-deep-purple',
              textClass: 'text-deep-purple-contrast',
            },
          },
          {
            type: 'link',
            label: 'Food App',
            route: '/apps/food-app/group-ticket-list',
            icon: icDateRange,
            screen_id:8,
            badge: {
              value: '12',
              bgClass: 'bg-deep-purple',
              textClass: 'text-deep-purple-contrast',
            },
          },
          {
            type: 'link',
            label: 'Member/Merchant Details',
            route: '/apps/tickets/applicant-details-list',
            icon: icChat,
            screen_id:28,
            badge: {
              value: '16',
              bgClass: 'bg-cyan',
              textClass: 'text-cyan-contrast',
            },
          },
          {
            type: 'link',
            label: 'Add Purchase Holding Tank',
            route: '/apps/add-banner/bannerlist',
            icon: icChat,
            screen_id:91,
            badge: {
              value: '16',
              bgClass: 'bg-cyan',
              textClass: 'text-cyan-contrast',
            },
          },
          {
            type: 'link',
            label: 'Add Invoice',
            route: '/apps/add-invoice/invoice-list',
            icon: icChat,
            screen_id:16,
            badge: {
              value: '16',
              bgClass: 'bg-cyan',
              textClass: 'text-cyan-contrast',
            },
          },


         
         
        ]
      },
   
    
      // {
      //   type: 'subheading',
      //   label: 'Accounts',
      //   children: [
      //     {
      //       type: 'link',
      //       label: 'Auctions',
      //       route: '/apps/payment-list/auction-list',
      //       icon: icChat,
      //       screen_id:7,
      //       badge: {
      //         value: '16',
      //         bgClass: 'bg-cyan',
      //         textClass: 'text-cyan-contrast',
      //       },
      //     },
      //     {
      //       type: 'link',
      //       label: 'Payment',
      //       route: '/apps/payment-list',
      //       icon: icChat,
      //       screen_id:8,
      //       badge: {
      //         value: '16',
      //         bgClass: 'bg-cyan',
      //         textClass: 'text-cyan-contrast',
      //       },
      //     },
      //     {
      //       type: 'link',
      //       label: 'Transaction',
      //       route: '/apps/general-refund',
      //       icon: icChat,
      //       screen_id:8,
      //       badge: {
      //         value: '16',
      //         bgClass: 'bg-cyan',
      //         textClass: 'text-cyan-contrast',
      //       },
      //     },
      //     {
      //       type: 'link',
      //       label: 'Receipt',
      //       route: '/apps/cancellation/receipt-list',
      //       icon: icChat,
      //       screen_id:10,
      //       badge: {
      //         value: '16',
      //         bgClass: 'bg-cyan',
      //         textClass: 'text-cyan-contrast',
      //       },
      //     },
      //     {
      //       type: 'link',
      //       label: 'Charges/Fees',
      //       route: '/apps/charges-form/charges-list',
      //       icon: icChat,
      //       screen_id:9,
      //       badge: {
      //         value: '16',
      //         bgClass: 'bg-cyan',
      //         textClass: 'text-cyan-contrast',
      //       },
      //     }
      //   ]
      // },
      // {
      //   type: 'subheading',
      //   label: 'Accounts Master',
      //   children: [
      //     {
      //       type: 'link',
      //       label: 'Cancellation',
      //       route: '/apps/cancellation',
      //       icon: icChat,
      //       screen_id:11,
      //       badge: {
      //         value: '16',
      //         bgClass: 'bg-cyan',
      //         textClass: 'text-cyan-contrast',
      //       },
      //     },
      //     {
      //       type: 'link',
      //       label: 'Fund Transfer',
      //       route: '/apps/cancellation/canceltransfer-list',
      //       icon: icChat,
      //       screen_id:12,
      //       badge: {
      //         value: '16',
      //         bgClass: 'bg-cyan',
      //         textClass: 'text-cyan-contrast',
      //       },
      //     },
      //     {
      //       type: 'link',
      //       label: 'Bank Details',
      //       route: '/apps/approval/bank-list',
      //       icon: icChat,
      //       screen_id:13,
      //       badge: {
      //         value: '16',
      //         bgClass: 'bg-cyan',
      //         textClass: 'text-cyan-contrast',
      //       },
      //     },
      //     {
      //       type: 'link',
      //       label: 'Settings',
      //       route: '/apps/settings',
      //       icon: icChat,
      //       screen_id:14,
      //       badge: {
      //         value: '16',
      //         bgClass: 'bg-cyan',
      //         textClass: 'text-cyan-contrast',
      //       },
      //     },
      //     {
      //       type: 'link',
      //       label: 'Payment Type',
      //       route: '/apps/payment-type',
      //       icon: icChat,
      //       screen_id:15,
      //       badge: {
      //         value: '16',
      //         bgClass: 'bg-cyan',
      //         textClass: 'text-cyan-contrast',
      //       },
      //     },
      //     {
      //       type: 'link',
      //       label: 'Add Roles',
      //       route: '/apps/add-role',
      //       icon: icChat,
      //       badge: {
      //         value: '16',
      //         bgClass: 'bg-cyan',
      //         textClass: 'text-cyan-contrast',
      //       },
      //     },
      //     {
      //       type: 'link',
      //       label: 'Permissions',
      //       route: '/apps/add-role/permissions',
      //       icon: icChat,
      //       screen_id:9,
      //       badge: {
      //         value: '16',
      //         bgClass: 'bg-cyan',
      //         textClass: 'text-cyan-contrast',
      //       },
      //     },
        //  {
        //     type: 'link',
        //     label: 'Add Holding Tank',
        //     route: '/apps/add-banner/bannerlist',
        //     icon: icChat,
        //     screen_id:9,
        //     badge: {
        //       value: '16',
        //       bgClass: 'bg-cyan',
        //       textClass: 'text-cyan-contrast',
        //     },
   //    },
      //     {
      //       type: 'link',
      //       label: 'Add Banner',
      //       route: '/apps/add-banner/bannerlist',
      //       icon: icChat,
      //       screen_id:9,
      //       badge: {
      //         value: '16',
      //         bgClass: 'bg-cyan',
      //         textClass: 'text-cyan-contrast',
      //       },
      //     }
      //   ]
      // },

      {
        type: 'subheading',
        label: 'C Care',
        children: [
          {
            type: 'link',
            label: 'Customer View',
            route: '/apps/charges-form/c-care-list',
            icon: icChat,
            screen_id:166,
            badge: {
              value: '16',
              bgClass: 'bg-cyan',
              textClass: 'text-cyan-contrast',
            },
          },
          {
            type: 'link',
            label: 'Settings',
            route: '/apps/settings',
            icon: icChat,
            screen_id:26,
            badge: {
              value: '16',
              bgClass: 'bg-cyan',
              textClass: 'text-cyan-contrast',
            },
          }
        ]
      },

      // {
      //   type: 'subheading',
      //   label: 'Reports',
      //   children: [
      //     {
      //       type: 'link',
      //       label: 'Customer Transaction Statement',
      //       route: '/apps/reports-list',
      //       icon: icChat,
      //       screen_id:7,
      //       badge: {
      //         value: '16',
      //         bgClass: 'bg-cyan',
      //         textClass: 'text-cyan-contrast',
      //       },
      //     },
      //     {
      //       type: 'link',
      //       label: 'General Reports',
      //       route: '/apps/reports-list/general-reports',
      //       icon: icChat,
      //       screen_id:7,
      //       badge: {
      //         value: '16',
      //         bgClass: 'bg-cyan',
      //         textClass: 'text-cyan-contrast',
      //       },
      //     }
        
      //   ]
      // },
      {
        type: 'subheading',
        label: 'Customize',
        children: []
      },
      {
        type: 'link',
        label: 'Configuration',
        route: () => this.layoutService.openConfigpanel(),
        icon: icSettings
      }
    ];
  }
}

import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { CommonService } from './commonService';
import { HttpClient, HttpHeaders,HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';

@Injectable()
export class HttpService {

    
  
    constructor(private http: HttpClient, private commonService: CommonService,private router: Router) { }
    get(url: string, headers?: HttpHeaders, params:any={}): Observable<any> {
        const self = this;
        let url1=CommonService.baseUrl+ url;
        return this.http.get(url1, this.getRequestOptions()) .pipe(
          catchError(
            (error) => {
              this.errorHandler(error);
              return error;
            }
        ))
  ;
    }

    delete(url: string, headers?: HttpHeaders, params:any={}): Observable<any> {
      const self = this;
      let url1=CommonService.baseUrl+ url;
      return this.http.delete(url1, this.getRequestOptions()) .pipe(
        catchError(
          (error) => {
            this.errorHandler(error);
            return error;
          }
      ))
;
  }


    errorHandler(error) {
      this.commonService.Loader(false);
      if(error.status == 401) {
        this.router.navigate(['/login']);
      }
      if(error.error.data) {
        let finalData:any=[];
        let data = error.error.data;
        for (let key in data) {
            
          for (let error in data[key]) {

            let obj ={
                  name:key,
                  value: data[key][error]
            }

            finalData.push(obj);

          }

        }

        if(finalData.length >0) {
                  
                this.commonService.setErrorData(finalData);
        }

    }
    }
    

     post(url: string, data?: any,enc=false,enckryky=false, headers?: HttpHeaders): Observable<any> {
        const self = this;
       const baseurl =enc?CommonService.merchantBaseUrl:CommonService.baseUrl;
        return this.http.post(baseurl+ url, data, this.getRequestOptions())
        .pipe(
          catchError(
            (error) => {
              this.errorHandler(error);
              return error;
            }
        ))
  
    }

    postMultiPart(url: string, data?: any,enc=false,enckryky=false, headers?: HttpHeaders): Observable<any> {
      const self = this;
     
      return this.http.post<any>(CommonService.baseUrl+ url, data, this.getRequestOptionsMultiPart())
      .pipe(
        catchError(
          (error) => {
            this.errorHandler(error);
            return error;
          }
      ))

  }

    put(url: string, data?: any,enc=false,enckryky=false, headers?: HttpHeaders): Observable<any> {
      const self = this;
     
      return this.http.put(CommonService.baseUrl+ url, data, this.getRequestOptions())
      .pipe(
        catchError(
          (error) => {
            return error;
          }
      ))

  }

  private getRequestOptionsMultiPart() {
    if(sessionStorage.getItem('token') && sessionStorage.getItem('token')!=''){
        const httpOptions = {
            headers: new HttpHeaders({
         
            
            }).set('Authorization',  'Bearer '+sessionStorage.getItem('token'))
          };
          return httpOptions;
    } else{
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'multipart/form-data',
              'X-Requested-With':'XMLHttpRequest'
            })
          };
          return httpOptions;
    }
  

}

  
        private getRequestOptions() {
        if(sessionStorage.getItem('token') && sessionStorage.getItem('token')!=''){
            const httpOptions = {
                headers: new HttpHeaders({
                  'Content-Type':  'application/json',
                  'Accept':  '*',
                  'X-Requested-With':'XMLHttpRequest',
                }).set('Authorization',  'Bearer '+sessionStorage.getItem('token'))
              };
              return httpOptions;
        } else{
            const httpOptions = {
                headers: new HttpHeaders({
                  'Content-Type':  'application/json',
                  'X-Requested-With':'XMLHttpRequest'
                })
              };
              return httpOptions;
        }
      
    
    }

}

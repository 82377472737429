import {BehaviorSubject,Subscription} from 'rxjs';
import * as _ from 'lodash';

export class CommonService {
    
    public memberList:any[]
    public static readonly env=true;
    isAdmin:boolean;
    isTemp:boolean;
    public sub :Subscription;
    //https://humanblockchain.net/live-merchant-api
    public static baseUrl="https://humanblockchain.net/member-api/api/admin/";
   public static merchantBaseUrl="https://humanblockchain.net/member-api/api/v1/";
    public errorArray: BehaviorSubject<any> = new BehaviorSubject<any>([]);

    public loader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public isLogin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public phone: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    // SELECT group_details.start_date as auction_date,groups.group_amount as chit_value,group_details.group_name,'' as ticket_no,
    // groups.start_time,group_details.serial_number as due_no,group_details.prize_amount,'' as adjustment,'SJC Waiting' as customer_name FROM `group_details` 
    // LEFT JOIN groups on groups.id=group_details.group_id
    // WHERE group_details.serial_number not IN(SELECT winners.installment FROM winners)
    // UNION
    // SELECT winners.created_at auction_date,groups.group_amount as chit_value,groups.group_name,
    // winners.serial_number as ticket_no,groups.start_date,winners.installment as due_no,winners.prize_amount,winners.bid_adjustment as adjustment,
    // concat(customers.first_name, ' ',customers.last_name) as customer_name  from winners LEFT JOIN groups on groups.id=winners.group_id LEFT JOIN customers on customers.id=winners.customer_id
    
    constructor() {
        
        if(!window.localStorage.isAdmin || window.localStorage.isAdmin!="null") {
            this.isAdmin = window.localStorage.isAdmin;
        }  
    
    }

    Loader(text:boolean) {
        this.loader.next(text);    
    }

    setErrorData(text:any) {
        this.errorArray.next(text);    
    }
    setPhone(text:string) {
        this.phone.next(text);    
    }


    findScreen(screenID) {
        const permissions =JSON.parse(localStorage.getItem('permissions'))
        const role_id = Number(sessionStorage.getItem('role_id'));
        if(screenID && role_id>0) {
          let result = permissions.find(data=>{
            return data.screen_id == screenID;
          });
          return result.edit==1;
      } else {
        return true;
      }
    }

    getMemberID(memberId) {
        if(memberId && memberId.indexOf('MY')!=0 &&  memberId.indexOf('My')!=0){
          memberId = 'MY'+this.pad(memberId,6);
        } else if(memberId) {
          memberId=memberId.slice(2);
          memberId = 'MY'+this.pad(memberId,6);
        }
        return memberId;
      }
  
      pad(n, len) {
        let s = n.toString();
        if (s.length < len) {
            s = ('0000000000' + s).slice(-len);
        }
        return s;
     }
  
}


<div class="footer" fxLayout="row">
  <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center" vexContainer>
    <a class="action"
       color="primary"
       fxFlex="none"
       href="//1.envato.market/0vkRO"
       id="get-vex"
       mat-raised-button>
   
    </a>
   
  </div>
</div>

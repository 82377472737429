import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { Authentication } from './authentication';

const routes: VexRoutes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/pages/auth/register/register.module').then(m => m.RegisterModule),
    canActivate:[Authentication]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboards/analytics',
        redirectTo: '/'
      },
      {
        path: '',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
        canActivate:[Authentication]
      },
      {
        path: 'apps',
        children: [
        
         
          {
            path: 'social',
            loadChildren: () => import('./pages/apps/social/social.module').then(m => m.SocialModule)
          },
          {
            path: 'online-request',
            loadChildren: () => import('./pages/apps/online-request/online-request.module').then(m => m.OnlineRequestModule),
            canActivate:[Authentication]
          },
          {
            path: 'merchant-cards',
            loadChildren: () => import('./pages/apps/group-series/group-series.module').then(m => m.GroupSeriesModule),
            canActivate:[Authentication]
          },
          {
            path: 'settings',
            loadChildren: () => import('./pages/apps/settings/settings.module').then(m => m.SettingsModule),
            canActivate:[Authentication]
          },
          {
            path: 'payment-type',
            loadChildren: () => import('./pages/apps/payment-type/aio-table.module').then(m => m.PaymentTypeSettingModule),
            canActivate:[Authentication]
          },

          {
            path: 'add-role',
            loadChildren: () => import('./pages/apps/add-roles/aio-table.module').then(m => m.AddRoleModule),
            canActivate:[Authentication]
          },

          {
            path: 'add-banner',
            loadChildren: () => import('./pages/apps/banner/banner-table.module').then(m => m.AddBannerModule),
            canActivate:[Authentication]
          },
          {
            path: 'add-invoice',
            loadChildren: () => import('./pages/apps/invoice/invoice-table.module').then(m => m.AddInvoiceModule),
            canActivate:[Authentication]
          },
           {
            path: 'branch',
            loadChildren: () => import('./pages/apps/organization-details/org-details.module').then(m => m.OrgDetailsModule),
            canActivate:[Authentication]
          },
           {
            path: 'group-form',
            loadChildren: () => import('./pages/apps/group-form/group-form.module').then(m => m.GroupFormModule),
             data: {
              containerEnabled: true
            },
            canActivate:[Authentication]
          },
          {
            path: 'cancellation',
            loadChildren: () => import('./pages/apps/cancellation/cancellation-details.module').then(m => m.CancellationModule),
             data: {
              containerEnabled: true
            },
            canActivate:[Authentication]
          },
          {
            path: 'group-org',
            loadChildren: () => import('./pages/apps/group-org/group-org.module').then(m => m.OrgFormModule),
             data: {
              containerEnabled: true
            },
            canActivate:[Authentication]
          },

          {
            path: 'reports-list',
            loadChildren: () => import('./pages/apps/reports/reports-table.module').then(m => m.ReportsModule),
             data: {
              containerEnabled: true
            },
            canActivate:[Authentication]
          },


          {
            path: 'payment-list',
            loadChildren: () => import('./pages/apps/auction-details/auction-details.module').then(m => m.AuctionsFormModule),
             data: {
              containerEnabled: true
            },
            canActivate:[Authentication]
          },
          {
            path: 'general-refund',
            loadChildren: () => import('./pages/apps/general-refund/refund-details.module').then(m => m.RefundFormModule),
             data: {
              containerEnabled: true
            },
            canActivate:[Authentication]
          },
            {
            path: 'tickets',
            loadChildren: () => import('./pages/apps/ticket-details/ticket-details.module').then(m => m.TicketDetailsFormModule),
             data: {
              containerEnabled: true
            },
            canActivate:[Authentication]
          },
          {
            path: 'food-app',
            loadChildren: () => import('./pages/apps/food-app/food-app.module').then(m => m.FoodAppFormModule),
             data: {
              containerEnabled: true
            },
            canActivate:[Authentication]
          },
          {
            path: 'charges-form',
            loadChildren: () => import('./pages/apps/charges-form/charges-form.module').then(m => m.ChargesFormModule),
             data: {
              containerEnabled: true
            },
            canActivate:[Authentication]
          },
          {
            path: 'approval',
            loadChildren: () => import('./pages/apps/approval/approval-details.module').then(m => m.ApprovalModule),
             data: {
              containerEnabled: true
            },
            canActivate:[Authentication]
          }
        ]
      },
      {
        path: 'pages',
        children: [
          {
            path: 'pricing',
            loadChildren: () => import('./pages/pages/pricing/pricing.module').then(m => m.PricingModule)
          },
          {
            path: 'faq',
            loadChildren: () => import('./pages/pages/faq/faq.module').then(m => m.FaqModule)
          },
         
          {
            path: 'invoice',
            loadChildren: () => import('./pages/pages/invoice/invoice.module').then(m => m.InvoiceModule)
          },
          {
            path: 'error-404',
            loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
          },
          {
            path: 'error-500',
            loadChildren: () => import('./pages/pages/errors/error-500/error-500.module').then(m => m.Error500Module)
          }
        ]
      },
      // {
      //   path: 'ui',
      //   children: [
         
      //     {
      //       path: 'forms/form-elements',
      //       loadChildren: () => import('./pages/ui/forms/form-elements/form-elements.module').then(m => m.FormElementsModule),
      //       data: {
      //         containerEnabled: true
      //       }
      //     },
      //     {
      //       path: 'forms/form-wizard',
      //       loadChildren: () => import('./pages/ui/forms/form-wizard/form-wizard.module').then(m => m.FormWizardModule),
      //       data: {
      //         containerEnabled: true
      //       }
      //     },
      //     {
      //       path: 'icons',
      //       loadChildren: () => import('./pages/ui/icons/icons.module').then(m => m.IconsModule)
      //     },
      //     {
      //       path: 'page-layouts',
      //       loadChildren: () => import('./pages/ui/page-layouts/page-layouts.module').then(m => m.PageLayoutsModule),
      //     },
      //   ]
      // },
      {
        path: '**',
        loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import {  HttpService } from './services/httpservice';
import {  CommonService } from './services/commonService'
import { Authentication } from './../app/authentication';
import { AlertDialogComponent } from './delete-dialog/delete-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [AppComponent,AlertDialogComponent],
  imports: [
    MatDialogModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    // Vex
    VexModule,
    CustomLayoutModule,
    MatButtonModule
  ],
  providers: [HttpService,CommonService,Authentication],
  bootstrap: [AppComponent]
})
export class AppModule { }

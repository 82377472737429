<h1 mat-dialog-title>
    Confirm
  </h1>
  
<mat-dialog-content>
    {{message}}
</mat-dialog-content>
<mat-dialog-actions  align="end">
    <button mat-raised-button (click)="onDismiss()" color="primary">No</button>
    <button mat-raised-button (click)="onConfirm()" color="accent">{{cancelButtonText}}</button>
</mat-dialog-actions>
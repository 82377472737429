<form (ngSubmit)="save()" [formGroup]="form">
  <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
   

   
    <h4 
        class="headline m-3"
        fxFlex="auto">Error Dialog</h4>


  </div>


  <mat-divider class="-mx-6 text-border"></mat-divider>

  <mat-dialog-content fxLayout="column">
  
    <div *ngFor="let data of errorData">
      
      <div class="alert alert-danger" role="alert">
        {{data.name}}  -  {{data.value}}
      </div>

    </div>
   
      



 
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Close</button>
   
  </mat-dialog-actions>
  <style>
    .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
  </style>


</form>

